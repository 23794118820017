.navbarBed {
  display: flex;
  justify-content: center;
  margin: 10px 0 60px;
}

.navbar {
  position: absolute;
  background-color: #07233b;
  z-index: 10;
  border-radius: 7px;
  padding: 0.8vh;
  display: flex;
  justify-content: center;
}

.navbarButton {
  color: #ffff;
  z-index: 10;
  background-color: rgb(75, 75, 75);
  font-size: 2.8vh;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 1vh;
  cursor: pointer;
}

.navbarSignoutButton {
  color: #1f1e1e;
  z-index: 10;
  background-color: #ffff;
  font-size: 2.8vh;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 1vh;
  cursor: pointer;
}

.navbarSignOutClientsButton {
  color: rgb(0, 0, 0);
  z-index: 10;
  background-color: rgb(255, 238, 0);
  font-size: 2.8vh;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 1vh;
  cursor: pointer;
}

.navbarPanicButton {
  color: #ffff;
  z-index: 10;
  background-color: red;
  font-size: 2.8vh;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 1vh;
  cursor: pointer;
}
