.loaderBed {
  background-color: rgb(56, 0, 121);
  padding: 3px;
  position: absolute;
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loaderText {
  color: #ffff;
  font-size: 14px;
  padding-top: 16px;
}
