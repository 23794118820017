.typeAddCreditTop {
  flex: 1;
  display: flex;
  justify-content: center;
}

.typeAddCreditTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.typeAddCreditLabel {
  color: #ffff;
  font-size: 7vh;
}

.typeAddCreditPcIcon {
  color: #ffff;
  font-size: 20vh;
}

.typeAddCreditTopRight {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.typeAddCreditTopRightContainer {
  padding-left: 3vh;
  display: flex;
}

.typeAddCreditTopRightLeft {
  flex: 1;
}

.typeAddCreditTopRightRight {
  flex: 1;
}

.typeAddCreditCreditText {
  color: #ffff;
  font-size: 3vh;
  margin: 3vh 0 3vh 0;
}

.typeAddCreditClearButtonBed {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.typeAddCreditClearButton {
  color: #ffd900;
  border: 2px solid #ffd900;
  background-color: #252424;
  font-weight: 600;
  border-radius: 25px;
  padding: 10px;
  margin-left: 5vh;
  cursor: pointer;
}

.typeAddCreditSubmitBed {
  display: flex;
  justify-content: center;
}

.typeAddCreditSubmitConfirm {
  color: #5eff00;
  font-size: 10vh;
  border-radius: 10px;
  padding: 1vh;
  cursor: pointer;
}

.typeAddCreditBottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.typeAddCreditBottomContainer {
  display: flex;
  justify-content: center;
}

.typeAddCreditValue10 {
  background-color: #1fad02;
  color: #2c2828;
  border-radius: 10px;
  font-size: 7vh;
  font-weight: 600;
  width: 10%;
  height: 15vh;
  margin: 1vh;
  text-align: center;
  cursor: pointer;
}

.typeAddCreditValue20 {
  background-color: #ca6c00;
  color: #2c2828;
  border-radius: 10px;
  font-size: 7vh;
  font-weight: 600;
  width: 10%;
  height: 15vh;
  margin: 1vh;
  text-align: center;
  cursor: pointer;
}

.typeAddCreditValue50 {
  background-color: #d23cff;
  color: #2c2828;
  border-radius: 10px;
  font-size: 7vh;
  font-weight: 600;
  width: 10%;
  height: 15vh;
  margin: 1vh;
  text-align: center;
  cursor: pointer;
}

.typeAddCreditValue100 {
  background-color: #00a7e9;
  color: #2c2828;
  border-radius: 10px;
  font-size: 7vh;
  font-weight: 600;
  width: 10%;
  height: 15vh;
  margin: 1vh;
  text-align: center;
  cursor: pointer;
}

.typeAddCreditValue200 {
  background-color: #ff2222;
  color: #2c2828;
  border-radius: 10px;
  font-size: 7vh;
  font-weight: 600;
  width: 10%;
  height: 15vh;
  margin: 1vh;
  text-align: center;
  cursor: pointer;
}

.typeAddCreditValueTextBed {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.transationErrorBed {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.transationErrorText {
  background-color: #ff2222;
  border: 3px solid #ffff;
  border-radius: 7px;
  padding: 3vh;
  color: #ffff;
  font-size: 4vh;
}
