.cashierTransactionHistoryButton {
  color: #ffff;
  font-size: 2.2vh;
  width: 19vh;
  background-color: #3f3b3b;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  text-align: center;
  cursor: pointer;
}

.transactionHistoryListHeader {
  color: #ffff;
  font-size: 3vh;
  text-align: center;
  margin-bottom: 2vh;
}

.transactionHistoryListBed {
  overflow-y: scroll;
}

.transactionHistoryListContainer {
  background-color: #040a55;
  margin: 0.3vh 0 0.3vh 0;
  display: flex;
  justify-content: space-around;
}

.transactionHistoryListCellLabel {
  color: #ffff;
  padding: 0.5vh;
  font-weight: 600;
}

.transactionHistoryListText {
  color: #ffff;
  padding: 0.5vh;
}
