.cashierUsersListBed {
  padding: 1vh;
  margin: 0.5vh 10vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cashierUsersListItem {
  background-color: #07233b;
  z-index: 10;
  border-radius: 7px;
  margin: 0.5vh;
  padding: 5vh;
}

.cashierUsersListItemLabel {
  color: #ffff;
  font-size: 5vh;
  text-align: center;
}

.cashierUsersListItemIconBed {
  display: flex;
  justify-content: center;
}

.cashierUsersListItemIcon {
  color: #ffff;
  font-size: 15vh;
  align-self: center;
  margin: 2svh;
}

.cashierUsersListBalance {
  color: #ffff;
  font-size: 2.2vh;
  background-color: #016b59;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh;
  text-align: center;
}

.cashierCheckCreditButton {
  color: #ffff;
  font-size: 2.2vh;
  background-color: #016b59;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  width: 19vh;
  text-align: center;
  cursor: pointer;
}

.cashierAddCreditButton {
  color: #ffff;
  font-size: 2.2vh;
  width: 19vh;
  background-color: #0e8803;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  text-align: center;
  cursor: pointer;
}

.cashierCashoutButton {
  color: #ffff;
  font-size: 2.2vh;
  width: 19vh;
  background-color: #ca5100;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  text-align: center;
  cursor: pointer;
}

.cashierTransactionHistoryButton {
  color: #ffff;
  font-size: 2.2vh;
  width: 19vh;
  background-color: #3f3b3b;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  text-align: center;
  cursor: pointer;
}

.cashierClearClientHistoryButton {
  color: rgb(37, 37, 37);
  font-size: 2.2vh;
  width: 19vh;
  background-color: #fbff00;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  text-align: center;
  cursor: pointer;
}

.cashierSignOutClientButton {
  color: #ffff;
  font-size: 2.2vh;
  width: 19vh;
  background-color: #000000;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  text-align: center;
  cursor: pointer;
}

.cashierCheckClientBalanceButton {
  color: rgb(255, 255, 255);
  font-size: 2.2vh;
  width: 19vh;
  background-color: #3d1166;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  text-align: center;
  cursor: pointer;
}

.cashierClearJackpotButton {
  color: #ffff;
  font-size: 2.2vh;
  width: 19vh;
  height: 19vh;
  background-color: #620388;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  -webkit-animation: flickerAnimation 0.5s infinite;
  -moz-animation: flickerAnimation 0.5s infinite;
  -o-animation: flickerAnimation 0.5s infinite;
  animation: flickerAnimation 0.5s infinite;
}

.cashierClearJackpotButtontext {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  font-size: 3vh;
  font-weight: 700;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cashierCashoutButtonFlicker {
  color: #ffff;
  font-size: 2.2vh;
  width: 19vh;
  background-color: #ca5100;
  border-radius: 7px;
  padding: 2vh;
  margin: 1vh 0 1vh 0;
  text-align: center;
  cursor: pointer;
  -webkit-animation: flickerAnimation 0.5s infinite;
  -moz-animation: flickerAnimation 0.5s infinite;
  -o-animation: flickerAnimation 0.5s infinite;
  animation: flickerAnimation 0.5s infinite;
}
