.messageModalBed {
  position: absolute;
  height: 80vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
}

.messageModalContainer {
  border-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.messageModalText {
  color: #ffff;
  font-size: 6vh;
  font-weight: 200;
  margin-bottom: 5vh;
  line-height: 8vh;
}

.messageModalButtonBed {
  align-self: center;
  display: flex;
}

.messageModalButton {
  background-color: rgb(2, 85, 179);
  color: #ffff;
  border-radius: 7px;
  font-size: 2.4vh;
  padding: 2vh;
  width: 50px;
  cursor: pointer;
  margin: 10px;
}
