@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: 'Exo', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family: 'Pacifico';
  src: local('Pacifico'),
    url(../assets/fonts/pacifico/Pacifico.ttf) format('truetype');
}

@font-face {
  font-family: 'Dites';
  src: local('Dites'), url(../assets/fonts/dites//Dited.otf) format('truetype');
}

@font-face {
  font-family: 'Chunk';
  src: local('Chunk'), url(../assets/fonts/chunk//Chunk.otf) format('truetype');
}

@import url('https://use.typekit.net/yjs2lsy.css');

*,
*:before,
*:after {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}
::-moz-selection {
  background: transparent;
}
::selection {
  background: transparent;
}

.appBackGroundBed {
  background: rgb(56, 0, 121);
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
}

.appBackGroundShapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.appBackGroundShapes li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.appBackGroundShapes li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.appBackGroundShapes li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.appBackGroundShapes li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.appBackGroundShapes li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.appBackGroundShapes li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.appBackGroundShapes li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.appBackGroundShapes li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.appBackGroundShapes li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.appBackGroundShapes li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.appBackGroundShapes li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.socketLoadingMessageBed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.socketLoadingMessageText {
  color: #ffff;
  text-align: center;
  font-size: 4vh;
  line-height: 50px;
}

.socketLoadingMessageButtonBed {
  display: flex;
  justify-content: center;
}

.socketLoadingMessageButton {
  background-color: red;
  color: #ffff;
  text-align: center;
  font-size: 4vh;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
