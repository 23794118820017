.cashierModalBed {
  position: absolute;
  height: 80vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
}

.cashierModalContainer {
  background-color: #2c2828;
  border-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cashierModalCancelBed {
  display: flex;
  justify-content: flex-end;
}

.cashierModalCancel {
  color: #ff0000;
  font-size: 7vh;
  border-radius: 10px;
  padding: 1vh;
  cursor: pointer;
}
