.cashOutCalculatorBed {
  height: 100%;
  display: flex;
  justify-content: center;
}

.cashOutCalculatorContainer {
  background-color: blueviolet;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  padding: 10px;
}

.cashOutCalculatorTop {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  margin-bottom: 10px;
  flex: 9;
  display: flex;
  flex-direction: column;
}

.cashOutCalculatorConfirmButton {
  color: rgb(255, 255, 255);
  background-color: rgb(16, 87, 1);
  border: 3px solid rgb(255, 255, 255);
  border-radius: 10px;
  padding: 10px 0;
  font-size: 25px;
  cursor: pointer;
}
.cashOutCalculatorConfirmButton:active {
  background-color: rgb(24, 146, 0);
}

.cashOutCalculatorDataRowsBed {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cashOutCalculatorDataRow {
  display: flex;
  justify-content: center;
}

.cashOutCalculatorDataTextLabel {
  font-size: 20px;
  font-weight: 100;
  margin-right: 15px;
}

.cashOutCalculatorDataText {
  font-size: 20px;
  font-weight: 900;
}

.cashOutCalculatorBottom {
  flex: 11;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cashOutCalculatorButtonRow {
  display: flex;
  justify-content: space-around;
}

.cashOutCalculatorButton {
  color: #ffff;
  background-color: rgb(41, 41, 41);
  flex: 1;
  font-size: 30px;
  border-radius: 10px;
  height: 9vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
}
.cashOutCalculatorButton:active {
  background-color: rgb(116, 112, 112);
}
