.typeCashOutLabel {
  color: #ffff;
  font-size: 10vh;
  text-align: center;
}

.typeCashOutPcIcon {
  color: #ffff;
  font-size: 20vh;
  text-align: center;
}

.typeCashOutBalanceText {
  color: #ffea32;
  font-size: 5vh;
  margin: 3vh 0 3vh 0;
}

.typeCashOutText {
  color: #ffff;
  font-size: 3.2vh;
  margin: 3vh 0 3vh 0;
}

.typeCashOutSubmitConfirmBed {
  display: flex;
  justify-content: center;
}

.typeCashOutSubmitConfirm {
  color: #ffff;
  border: 1px solid #ffff;
  background-color: #205700;
  width: 80px;
  font-size: 3vh;
  border-radius: 7px;
  padding: 2vh;
  margin: 0 10px;
  cursor: pointer;
}
